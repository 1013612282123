<template lang="pug">
v-content
  v-container(grid-list-xl)
    v-layout(row, wrap)
      v-flex.hidden-sm-and-down(md3)
        v-card.elevation-12
          v-list(dense, subheader)
            v-subheader 
              h4 Configuración
            v-divider

            v-list-tile
              v-list-tile-content
                v-list-tile-title 
                  router-link(to="/settings/profile") Cuenta
                  
            v-list-tile
              v-list-tile-content
                v-list-tile-title 
                  router-link(to="/settings/plans") Planes

      v-flex(xs12, md8, offset-md1)
        v-layout(row)
          v-flex
            router-view

</template>

<script>

export default {
  name: "Settings",
  computed: {
    hashLocation() {
      return this.$route.hash;
    },
  },
};
</script>

<style lang="less" scoped>
</style>
